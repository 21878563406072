<template>
  <form novalidate @submit.prevent="" class="edit-personal-data">
    <ContactInformation />
    <PersonalInformation />
    <div class="edit-personal-data__actions">
      <Button back @click.native="back"> Назад </Button>
      <Button type="submit"> Сохранить </Button>
    </div>
  </form>
</template>

<script>
import "./edit-personal-data.scss";

import ContactInformation from "@index/components/application/contact-information/ContactInformation";
import PersonalInformation from "@index/components/application/personal-information/PersonalInformation";
import Button from "@index/components/gui/button/Button";

export default {
  name: "EditPersonalData",
  methods: {
    back() {
      this.$router.push({ name: "Profile" });
    },
  },
  components: {
    ContactInformation,
    PersonalInformation,
    Button,
  },
};
</script>
